import { default as index7d8Fwop4nrMeta } from "/hermes/pages/categories/index.vue?macro=true";
import { default as registerZbclwkM1KwMeta } from "/hermes/pages/categories/register.vue?macro=true";
import { default as _91id_93FZIF4U1xCiMeta } from "/hermes/pages/goods-issues/[id].vue?macro=true";
import { default as indexAoT2mFAWlJMeta } from "/hermes/pages/goods-issues/index.vue?macro=true";
import { default as registerj5N8knovIiMeta } from "/hermes/pages/goods-issues/register.vue?macro=true";
import { default as index1ipyKkvg6AMeta } from "/hermes/pages/goods-receipts/index.vue?macro=true";
import { default as registerDHJjeAIb06Meta } from "/hermes/pages/goods-receipts/register.vue?macro=true";
import { default as uploaduWNkW01AFWMeta } from "/hermes/pages/goods-receipts/upload.vue?macro=true";
import { default as _91id_93jFiFPdcLLaMeta } from "/hermes/pages/goods-returns/[id].vue?macro=true";
import { default as indexyst68FxuZvMeta } from "/hermes/pages/goods-returns/index.vue?macro=true";
import { default as indexFQctxd7MmnMeta } from "/hermes/pages/index.vue?macro=true";
import { default as _91id_93fSQazMDE5XMeta } from "/hermes/pages/items/[id].vue?macro=true";
import { default as indexG58Gge7kLHMeta } from "/hermes/pages/items/index.vue?macro=true";
import { default as registereJdzJ8NqyjMeta } from "/hermes/pages/items/register.vue?macro=true";
import { default as uploadeYuoTmgrizMeta } from "/hermes/pages/items/upload.vue?macro=true";
export default [
  {
    name: "categories",
    path: "/categories",
    component: () => import("/hermes/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "categories-register",
    path: "/categories/register",
    component: () => import("/hermes/pages/categories/register.vue").then(m => m.default || m)
  },
  {
    name: "goods-issues-id",
    path: "/goods-issues/:id()",
    component: () => import("/hermes/pages/goods-issues/[id].vue").then(m => m.default || m)
  },
  {
    name: "goods-issues",
    path: "/goods-issues",
    component: () => import("/hermes/pages/goods-issues/index.vue").then(m => m.default || m)
  },
  {
    name: "goods-issues-register",
    path: "/goods-issues/register",
    component: () => import("/hermes/pages/goods-issues/register.vue").then(m => m.default || m)
  },
  {
    name: "goods-receipts",
    path: "/goods-receipts",
    component: () => import("/hermes/pages/goods-receipts/index.vue").then(m => m.default || m)
  },
  {
    name: "goods-receipts-register",
    path: "/goods-receipts/register",
    component: () => import("/hermes/pages/goods-receipts/register.vue").then(m => m.default || m)
  },
  {
    name: "goods-receipts-upload",
    path: "/goods-receipts/upload",
    component: () => import("/hermes/pages/goods-receipts/upload.vue").then(m => m.default || m)
  },
  {
    name: "goods-returns-id",
    path: "/goods-returns/:id()",
    component: () => import("/hermes/pages/goods-returns/[id].vue").then(m => m.default || m)
  },
  {
    name: "goods-returns",
    path: "/goods-returns",
    component: () => import("/hermes/pages/goods-returns/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/hermes/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "items-id",
    path: "/items/:id()",
    component: () => import("/hermes/pages/items/[id].vue").then(m => m.default || m)
  },
  {
    name: "items",
    path: "/items",
    component: () => import("/hermes/pages/items/index.vue").then(m => m.default || m)
  },
  {
    name: "items-register",
    path: "/items/register",
    component: () => import("/hermes/pages/items/register.vue").then(m => m.default || m)
  },
  {
    name: "items-upload",
    path: "/items/upload",
    component: () => import("/hermes/pages/items/upload.vue").then(m => m.default || m)
  }
]